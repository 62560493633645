import { City } from "./cityModel";

export class Country {
    id: string;
    displayKey: string;
    phoneCode: string;
    isoCodeTwo: string;
    isoCodeThree: string;
    currencyCode: string;
    status: 'shown' | 'hidden';
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format

    // Appended Attributes
    displayName: string;
    currencyName: string;

    // Relations
    cities: City[];

    constructor() {
        this.id = '';
        this.displayKey = '';
        this.phoneCode = '';
        this.isoCodeTwo = '';
        this.isoCodeThree = '';
        this.currencyCode = '';
        this.status = 'shown';
        this.createdAt = new Date();
        this.updatedAt = new Date();
        this.displayName = '';
        this.currencyName = '';

        this.cities = [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromData(data: any): Country {
        const country = new Country();

        country.id = data.id || '';
        country.displayKey = data.display_key || '';
        country.phoneCode = data.phone_code || '';
        country.isoCodeTwo = data.iso_code_two || '';
        country.isoCodeThree = data.iso_code_three || '';
        country.currencyCode = data.currency_code || '';
        country.status = data.status || 'shown';
        country.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        country.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();
        country.displayName = data.display_name || '';
        country.currencyName = data.currency_name || '';

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        country.cities = data.cities ? data.cities.map((city: any) => City.fromData(city)) : [];

        return country;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        return {
            id: this.id,
            display_key: this.displayKey,
            phone_code: this.phoneCode,
            iso_code_two: this.isoCodeTwo,
            iso_code_three: this.isoCodeThree,
            currency_code: this.currencyCode,
            status: this.status,
            created_at: this.createdAt.toISOString(),
            updated_at: this.updatedAt.toISOString(),

            display_name: this.displayName,
            currency_name: this.currencyName,
        }
    }
}
