/* eslint-disable @typescript-eslint/no-explicit-any */
import { Merchant } from "./merchantModel"; // Assuming you have a model for Merchant
import { Service } from "./serviceModel"; // Assuming you have a model for Service
import { ServiceImage } from "./serviceImageModel"; // Assuming you have a model for ServiceImage
import { Criteria } from "./criteriaModel";

export class ServicePackage {
    id: string;
    serviceId: string;
    merchantId: string;
    displayName: string;
    description: string;
    thumbnail: string;
    price: number;
    taxPercentage: number;
    capacity: number;
    slotsPerDay: number;
    isFeatured: boolean;
    status: 'shown' | 'hidden';
    reviewsCount: number;
    reviewsAvg: number;
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format

    autoApplyDiscountCode: number; // Optional field
    criteria: Criteria[];
    closedDays: string[];

    // Relationships
    merchant: Merchant; // Assuming Merchant is a related model
    service: Service; // Assuming Service is a related model
    serviceImages: ServiceImage[]; // Assuming ServiceImage is a related model

    constructor() {
        this.id = '';
        this.serviceId = '';
        this.merchantId = '';
        this.displayName = '';
        this.description = '';
        this.thumbnail = '';
        this.price = 0;
        this.taxPercentage = 0;
        this.capacity = 0;
        this.slotsPerDay = 1;
        this.isFeatured = false;
        this.status = 'shown';
        this.reviewsCount = 0;
        this.reviewsAvg = 0;
        this.createdAt = new Date();
        this.updatedAt = new Date();

        this.autoApplyDiscountCode = 0;
        this.criteria = [];
        this.closedDays = [];

        this.merchant = new Merchant();
        this.service = new Service();
        this.serviceImages = [];
    }

    static fromData(data: any): ServicePackage {
        const servicePackage = new ServicePackage();

        servicePackage.id = data.id || '';
        servicePackage.serviceId = data.service_id || '';
        servicePackage.merchantId = data.merchant_id || '';
        servicePackage.displayName = data.display_name || '';
        servicePackage.description = data.description || '';
        servicePackage.thumbnail = data.thumbnail || '';
        servicePackage.price = data.price !== undefined ? parseFloat(data.price) : 0;
        servicePackage.taxPercentage = data.tax_percentage !== undefined ? parseFloat(data.tax_percentage) : 0;
        servicePackage.capacity = data.capacity || 0;
        servicePackage.slotsPerDay = data.slots_per_day || 1;
        servicePackage.isFeatured = data.is_featured || false;
        servicePackage.status = data.status || 'shown';
        servicePackage.reviewsCount = data.reviews_count || 0;
        servicePackage.reviewsAvg = data.reviews_avg || 0;
        servicePackage.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        servicePackage.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();

        servicePackage.autoApplyDiscountCode = data.auto_apply_discount_code || 0;
        servicePackage.criteria = data.criteria ? data.criteria.map((criteriaData: any) => Criteria.fromData(criteriaData)) : [];
        servicePackage.closedDays = data.closed_days || [];

        servicePackage.merchant = data.merchant ? Merchant.fromData(data.merchant) : new Merchant();
        servicePackage.service = data.service ? Service.fromData(data.service) : new Service();
        servicePackage.serviceImages = data.service_images ? data.service_images.map((image: any) => ServiceImage.fromData(image)) : [];

        return servicePackage;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        return {
            id: this.id,
            service_id: this.serviceId,
            merchant_id: this.merchantId,
            display_name: this.displayName,
            description: this.description,
            thumbnail: this.thumbnail,
            price: this.price,
            tax_percentage: this.taxPercentage,
            capacity: this.capacity,
            slots_per_day: this.slotsPerDay,
            is_featured: this.isFeatured,
            status: this.status,
            reviews_count: this.reviewsCount,
            reviews_avg: this.reviewsAvg,
            created_at: this.createdAt.toISOString(),
            updated_at: this.updatedAt.toISOString(),

            auto_apply_discount_code: this.autoApplyDiscountCode,
            closed_days: this.closedDays,

            // sync attributes
            criteria_list: this.criteria,
            service_images: this.serviceImages,
            service_image_urls: (this.serviceImages ?? []).map((image) => image.imagePath),
        };
    }
}
