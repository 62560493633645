import { defineStore } from 'pinia';
import { parse, stringify } from 'zipson';

import env from '@/env';
import { MediaFile } from '@/api/models/models';
import { backendApi, AxiosError } from '@/http';
import { encryptData, decryptData } from '@/utils/crypto';
import { $t } from '@/i18n';

type DataState = {
  _loading: boolean,
  _alert: {
    text: string | null,
    type: 'info' | 'success' | 'warning' | 'error',
  },
  _mediaFiles: {
    images: MediaFile[];
    directories: string[];
    expiration: number;
  };
};

export default defineStore('data', {
  state: (): DataState => ({
    _loading: false,
    _alert: {
      text: null,
      type: 'info',
    },
    _mediaFiles: {
      images: [],
      directories: [],
      expiration: 0,
    },
  }),

  getters: {
    loading: (state) => state._loading,
    alert: (state) => state._alert,
    mediaFiles: (state) => state._mediaFiles,
  },
  actions: {
    setAlert(text: string | null, type: 'info' | 'success' | 'warning' | 'error' = 'info') {
      this._alert.text = text;
      this._alert.type = type;
    },
    toggleLoading() {
      this._loading = !this._loading;
    },
    resetAlert() {
      this._alert.text = null;
      this._alert.type = 'info';
    },
    async getMediaFiles(force = false): Promise<{ images: MediaFile[]; directories: string[]; expiration: number }> {
      try {
        // Set loading state, reset alert
        this._loading = true;
        this.resetAlert();

        // return stored media files if they are not expired
        if (!force && this._mediaFiles.expiration >= new Date().getTime() && this._mediaFiles.directories.length > 0) {
          return this._mediaFiles;
        }

        // Make the API request
        const result = await backendApi.get('/media-files');

        // Update reactive data with the received data
        const data = result.data.data;

        // Update the media files state
        this.setMediaFiles(data);

        return this._mediaFiles;
      } catch (error: unknown) {
        // Handle errors, show an alert
        let message = $t('ERROR.FAILED_TO_LOAD_DATA');
        if (error instanceof AxiosError && error.response?.data.message) {
          message = error.response.data.message;
        }
        this.setAlert(message, 'error');
        return { images: [], directories: [], expiration: 0 };
      } finally {
        this._loading = false;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setMediaFiles(data: any[]) {
      this._mediaFiles.images = [];
      this._mediaFiles.directories = [];

      data.forEach(element => {
        const item = MediaFile.fromData(element);
        this._mediaFiles.images.push(item);
        this._mediaFiles.directories.push(item.directory);
      });

      // remove duplicated directories
      this._mediaFiles.directories = [...new Set(this._mediaFiles.directories)];

      // update expiration
      const expirationTime = 60 * 60 * 1000; // 3 hour in milliseconds
      const currentTime = new Date().getTime();
      this._mediaFiles.expiration = currentTime + expirationTime;
    },
    addDirectory(directory: string) {
      if (!this._mediaFiles.directories.includes(directory)) {
        this._mediaFiles.directories.push(directory);
      }
    },
    addImage(image: MediaFile) {
      const index = this._mediaFiles.images.findIndex(item => item.id === image.id);

      if (index !== -1) {
        this._mediaFiles.images[index] = image;
      } else {
        this._mediaFiles.images.push(image);
      }

      this.addDirectory(image.directory);
    }
  },
  persist: {
    debug: env.mode === 'local',
    key: env.app.webStorageNamespace + '.data.store',
    storage: window.localStorage,
    serializer: {
      serialize(state) {
        const encryptedData = encryptData(stringify(state));
        return encryptedData;
      },
      deserialize(data) {
        const decryptedData = decryptData(data);
        return decryptedData ? parse(decryptedData) : null;
      },
    },
  },
});
