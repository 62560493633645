export type StatusListKey =
  | 'all'
  | 'draft'
  | 'expired'
  | 'requested'
  | 'accepted'
  | 'rejected'
  | 'paid'
  | 'canceled'
  | 'deleted'
  | 'active'
  | 'inactive'
  | 'blocked'
  | 'shown'
  | 'hidden'
  | 'pending'
  | 'unknown'
  | 'solved'
  | 'unpaid'
  | 'archived'
  | 'other';

export type statusListType = {
  label: string;
  icon: string;
  color: string;
};

export const statusList: Record<StatusListKey, statusListType> = {
  all: {
    label: 'STATUS_STRINGS.ALL',
    icon: 'mdi-format-list-bulleted',
    color: 'grey',
  },
  requested: {
    label: 'STATUS_STRINGS.REQUESTED',
    icon: 'mdi-stamper',
    color: 'info',
  },
  accepted: {
    label: 'STATUS_STRINGS.ACCEPTED',
    icon: 'mdi-check',
    color: 'success',
  },
  rejected: {
    label: 'STATUS_STRINGS.REJECTED',
    icon: 'mdi-close-thick',
    color: 'error',
  },
  paid: {
    label: 'STATUS_STRINGS.PAID',
    icon: 'mdi-currency-usd',
    color: 'success',
  },
  canceled: {
    label: 'STATUS_STRINGS.CANCELED',
    icon: 'mdi-cancel',
    color: 'grey',
  },
  deleted: {
    label: 'STATUS_STRINGS.DELETED',
    icon: 'mdi-delete',
    color: 'error',
  },
  active: {
    label: 'STATUS_STRINGS.ACTIVE',
    icon: 'mdi-check',
    color: 'success',
  },
  inactive: {
    label: 'STATUS_STRINGS.INACTIVE',
    icon: 'mdi-pause-octagon-outline',
    color: 'grey',
  },
  blocked: {
    label: 'STATUS_STRINGS.BLOCKED',
    icon: 'mdi-cancel',
    color: 'error',
  },
  shown: {
    label: 'STATUS_STRINGS.SHOWN',
    icon: 'mdi-eye-check-outline',
    color: 'success',
  },
  hidden: {
    label: 'STATUS_STRINGS.HIDDEN',
    icon: 'mdi-eye-remove-outline',
    color: 'grey',
  },
  pending: {
    label: 'STATUS_STRINGS.PENDING',
    icon: 'mdi-clock-time-eight-outline',
    color: 'info',
  },
  unknown: {
    label: 'STATUS_STRINGS.UNKNOWN',
    icon: 'mdi-information',
    color: 'grey',
  },
  solved: {
    label: 'STATUS_STRINGS.SOLVED',
    icon: 'mdi-check',
    color: 'success',
  },
  unpaid: {
    label: 'STATUS_STRINGS.UNPAID',
    icon: 'mdi-cash-clock',
    color: 'warning',
  },
  draft: {
    label: 'STATUS_STRINGS.DRAFT',
    icon: 'mdi-pen',
    color: 'grey',
  },
  expired: {
    label: 'STATUS_STRINGS.EXPIRED',
    icon: 'mdi-clock-remove-outline',
    color: 'grey',
  },
  archived: {
    label: 'STATUS_STRINGS.ARCHIVED',
    icon: 'mdi-information',
    color: 'grey',
  },
  other: {
    label: '---',
    icon: 'mdi-information',
    color: 'grey',
  },
};

export function getStatus(status: string | null | undefined): statusListType {
  if (!status || !(status in statusList)) {
    status = 'other';
  }

  return statusList[status as StatusListKey];
}
