import { ServiceType } from './serviceTypeModel';

export class Category {
  id: string;
  displayName: string;
  description: string;
  thumbnail: string;
  isFeatured: boolean;
  status: 'shown' | 'hidden';
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relations
  serviceTypes: ServiceType[];

  constructor() {
    this.id = '';
    this.displayName = '';
    this.description = '';
    this.thumbnail = '';
    this.isFeatured = false;
    this.status = 'shown';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.serviceTypes = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): Category {
    const category = new Category();

    category.id = data.id || '';
    category.displayName = data.display_name || '';
    category.description = data.description || '';
    category.thumbnail = data.thumbnail || '';
    category.isFeatured =
      data.is_featured !== undefined ? Boolean(data.is_featured) : false;
    category.status = data.status || 'shown';
    category.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    category.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    category.serviceTypes = data.service_types ? data.service_types.map((serviceType: any) => ServiceType.fromData(serviceType)) : [];

    return category;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      display_name: this.displayName,
      description: this.description,
      thumbnail: this.thumbnail,
      is_featured: this.isFeatured,
      status: this.status,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),
    };
  }
}
