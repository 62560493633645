import { Country } from "./countryModel";

export class City {
    id: string;
    countryId: string;
    displayKey: string;
    phoneCode: string;
    status: 'shown' | 'hidden';
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format

    // Appended Attributes
    displayName: string;

    // Relations
    country: Country;

    constructor() {
        this.id = '';
        this.countryId = '';
        this.displayKey = '';
        this.phoneCode = '';
        this.status = 'shown';
        this.createdAt = new Date();
        this.updatedAt = new Date();
        this.displayName = '';

        this.country = new Country();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromData(data: any): City {
        const city = new City();

        city.id = data.id || '';
        city.countryId = data.country_id || '';
        city.displayKey = data.display_key || '';
        city.phoneCode = data.phone_code || '';
        city.status = data.status || 'shown';
        city.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        city.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();
        city.displayName = data.display_name || '';

        city.country = data.country ? Country.fromData(data.country) : new Country();

        return city;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        return {
            id: this.id,
            country_id: this.countryId,
            display_key: this.displayKey,
            phone_code: this.phoneCode,
            status: this.status,
            created_at: this.createdAt.toISOString(),
            updated_at: this.updatedAt.toISOString(),
            display_name: this.displayName
        }
    }
}
