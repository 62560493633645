export class CriteriaItem {
    id: string;
    criteriaId: string;
    title: string;
    questionType?: 'number' | 'single-line' | 'multi-line';
    answer?: string;
    isActive: boolean;
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format

    constructor() {
        this.id = '';
        this.criteriaId = '';
        this.title = '';
        this.questionType = undefined;
        this.answer = undefined;
        this.isActive = false;
        this.createdAt = new Date();
        this.updatedAt = new Date();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromData(data: any): CriteriaItem {
        const criteriaItem = new CriteriaItem();

        criteriaItem.id = data.id || '';
        criteriaItem.criteriaId = data.criteria_id || '';
        criteriaItem.title = data.title || '';
        criteriaItem.questionType = data.question_type || undefined;
        criteriaItem.answer = data.answer || undefined;
        criteriaItem.isActive = data.is_active !== undefined ? Boolean(data.is_active) : false;
        criteriaItem.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        criteriaItem.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();

        return criteriaItem;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        return {
            id: this.id,
            criteria_id: this.criteriaId,
            title: this.title,
            question_type: this.questionType,
            answer: this.answer,
            is_active: this.isActive,
            created_at: this.createdAt.toISOString(),
            updated_at: this.updatedAt.toISOString(),
        };
    }
}
