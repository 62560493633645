import { User } from './userModel';

export class Merchant {
  id: string;
  businessName: string;
  licenseType: 'freelance_license' | 'commercial_register';
  licenseNumber: string;
  description: string;
  logo: string;
  address: string;
  responseTime: number; // in minutes
  isFeatured: boolean;
  reviewsCount: number;
  reviewsAvg: number;
  userId: string;
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relations
  user: User;

  constructor() {
    this.id = '';
    this.businessName = '';
    this.licenseType = 'commercial_register';
    this.licenseNumber = '';
    this.description = '';
    this.logo = '';
    this.address = '';
    this.responseTime = 0;
    this.isFeatured = false;
    this.reviewsCount = 0;
    this.reviewsAvg = 0.0;
    this.userId = '';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.user = new User();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): Merchant {
    const merchant = new Merchant();

    merchant.id = data.id || '';
    merchant.businessName = data.business_name || '';
    merchant.licenseType = data.license_type || 'commercial_register';
    merchant.licenseNumber = data.license_number || '';
    merchant.description = data.description || '';
    merchant.logo = data.logo || '';
    merchant.address = data.address || '';
    merchant.responseTime = data.response_time || 0;
    merchant.isFeatured =
      data.is_featured !== undefined ? Boolean(data.is_featured) : false;
    merchant.reviewsCount =
      data.reviews_count !== undefined ? parseInt(data.reviews_count, 10) : 0;
    merchant.reviewsAvg =
      data.reviews_avg !== undefined ? parseFloat(data.reviews_avg) : 0.0;
    merchant.userId = data.user_id || '';
    merchant.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    merchant.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    merchant.user = data.user ? User.fromData(data.user) : new User();

    return merchant;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      business_name: this.businessName,
      license_type: this.licenseType,
      license_number: this.licenseNumber,
      description: this.description,
      logo: this.logo,
      address: this.address,
      response_time: this.responseTime,
      is_featured: this.isFeatured,
      reviews_count: this.reviewsCount,
      reviews_avg: this.reviewsAvg,
      user_id: this.userId,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),
      user: this.user.toData(),
    };
  }
}
