/* eslint-disable @typescript-eslint/no-explicit-any */
import { Client } from './clientModel';
import { EventType } from './eventTypeModel';
import { Booking } from './bookingModel';

export class ClientEvent {
  id: string;
  clientId: string;
  eventTypeId: string;
  title: string;
  date: Date;
  isArchived: boolean;
  isSelected: boolean;
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relations
  client: Client;
  eventType: EventType;
  bookings: Booking[];

  constructor() {
    this.id = '';
    this.clientId = '';
    this.eventTypeId = '';
    this.title = '';
    this.date = new Date();
    this.isArchived = false;
    this.isSelected = false;
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.client = new Client();
    this.eventType = new EventType();
    this.bookings = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): ClientEvent {
    const clientEvent = new ClientEvent();

    clientEvent.id = data.id || '';
    clientEvent.clientId = data.client_id || '';
    clientEvent.eventTypeId = data.event_type_id || '';
    clientEvent.title = data.title || '';
    clientEvent.date = data.date ? new Date(data.date) : new Date();
    clientEvent.isArchived =
      data.is_archived !== undefined ? Boolean(data.is_archived) : false;
    clientEvent.isSelected =
      data.is_selected !== undefined ? Boolean(data.is_selected) : false;
    clientEvent.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    clientEvent.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    clientEvent.client = data.client
      ? Client.fromData(data.client)
      : new Client();
    clientEvent.eventType = data.event_type
      ? EventType.fromData(data.event_type)
      : new EventType();

    clientEvent.bookings = data.bookings
      ? data.bookings.map((booking: any) => Booking.fromData(booking))
      : [];

    return clientEvent;
  }

  toData(): Record<string, any> {
    return {
      id: this.id,
      client_id: this.clientId,
      event_type_id: this.eventTypeId,
      title: this.title,
      date: this.date.toISOString().split('T')[0],
      is_archived: this.isArchived,
      is_selected: this.isSelected,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),

      client_user_id: this.client.userId,
      bookings: this.bookings.map((booking) => booking.toData()),
    };
  }
}
