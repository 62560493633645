import { CriteriaItem } from "./criteriaItemModel";

export class Criteria {
    id: string;
    title: string;
    type: 'single-choice' | 'multiple-choices' | 'dropdown-choices' | 'questions';
    status: 'shown' | 'hidden';
    values: string[];
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format

    // Relations
    criteriaItems: CriteriaItem[];

    constructor() {
        this.id = '';
        this.title = '';
        this.type = 'single-choice'; // Default type
        this.status = 'shown'; // Default status
        this.values = [];
        this.createdAt = new Date();
        this.updatedAt = new Date();

        this.criteriaItems = [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromData(data: any): Criteria {
        const criteria = new Criteria();

        criteria.id = data.id || '';
        criteria.title = data.title || '';
        criteria.type = data.type || 'single-choice';
        criteria.status = data.status || 'shown';
        criteria.values = data.values || [];
        criteria.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        criteria.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();

        criteria.criteriaItems = data.criteria_items
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ? data.criteria_items.map((item: any) => CriteriaItem.fromData(item))
            : [];

        return criteria;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        return {
            id: this.id,
            title: this.title,
            type: this.type,
            status: this.status,
            values: this.values,
            created_at: this.createdAt.toISOString(),
            updated_at: this.updatedAt.toISOString(),

            criteria_items: this.criteriaItems.map((item) => item.toData()),
        };
    }
}
