import { type RouteRecordRaw } from 'vue-router';

// Advertisement Types
import AdvertisementTypeForm from '@/views/advertisementTypes/AdvertisementTypeForm.vue';
import AdvertisementTypesPage from '@/views/advertisementTypes/AdvertisementTypesPage.vue';

// Cities
import CityForm from '@/views/cities/CityForm.vue';
import CitiesPage from '@/views/cities/CitiesPage.vue';

// Countries
import CountryForm from '@/views/countries/CountryForm.vue';
import CountriesPage from '@/views/countries/CountriesPage.vue';

// Criterias
import CriteriaForm from '@/views/criterias/CriteriaForm.vue';
import CriteriasPage from '@/views/criterias/CriteriasPage.vue';

// Event Types
import EventTypeForm from '@/views/eventTypes/EventTypeForm.vue';
import EventTypesPage from '@/views/eventTypes/EventTypesPage.vue';

// Service Types
import ServiceTypeForm from '@/views/serviceTypes/ServiceTypeForm.vue';
import ServiceTypesPage from '@/views/serviceTypes/ServiceTypesPage.vue';

// Categories
import CategoryForm from '@/views/categories/CategoryForm.vue';
import CategoriesPage from '@/views/categories/CategoriesPage.vue';

const uuidRegex =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

const routes: RouteRecordRaw[] = [
  // advertisement-types -----------------------------------------------------------------
  {
    path: '/advertisement-types',
    name: 'advertisementTypes',
    meta: { userTypes: ['superadmin'] },
    redirect: () => ({
      name: 'advertisementTypes.tab',
      params: { tab: 'all' },
    }),
    children: [
      {
        path: ':tab(all|shown|hidden)',
        component: AdvertisementTypesPage,
        name: 'advertisementTypes.tab',
        meta: { userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: AdvertisementTypeForm,
        name: 'advertisementType',
        meta: { mode: 'view', userTypes: ['superadmin'] },
      },
      {
        path: 'create',
        component: AdvertisementTypeForm,
        name: 'advertisementTypeCreate',
        meta: { mode: 'create', userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: AdvertisementTypeForm,
        name: 'advertisementTypeUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin'] },
      },
    ],
  },

  // cities -----------------------------------------------------------------
  {
    path: '/cities',
    name: 'cities',
    meta: { userTypes: ['superadmin'] },
    redirect: () => ({ name: 'cities.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|shown|hidden)',
        component: CitiesPage,
        name: 'cities.tab',
        meta: { userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: CityForm,
        name: 'city',
        meta: { mode: 'view', userTypes: ['superadmin'] },
      },
      {
        path: 'create',
        component: CityForm,
        name: 'cityCreate',
        meta: { mode: 'create', userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: CityForm,
        name: 'cityUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin'] },
      },
    ],
  },

  // countries -----------------------------------------------------------------
  {
    path: '/countries',
    name: 'countries',
    meta: { userTypes: ['superadmin'] },
    redirect: () => ({ name: 'countries.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|shown|hidden)',
        component: CountriesPage,
        name: 'countries.tab',
        meta: { userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: CountryForm,
        name: 'country',
        meta: { mode: 'view', userTypes: ['superadmin'] },
      },
      {
        path: 'create',
        component: CountryForm,
        name: 'countryCreate',
        meta: { mode: 'create', userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: CountryForm,
        name: 'countryUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin'] },
      },
    ],
  },

  // criterias -----------------------------------------------------------------
  {
    path: '/criterias',
    name: 'criterias',
    meta: { userTypes: ['superadmin'] },
    redirect: () => ({ name: 'criterias.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|shown|hidden)',
        component: CriteriasPage,
        name: 'criterias.tab',
        meta: { userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: CriteriaForm,
        name: 'criteria',
        meta: { mode: 'view', userTypes: ['superadmin'] },
      },
      {
        path: 'create',
        component: CriteriaForm,
        name: 'criteriaCreate',
        meta: { mode: 'create', userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: CriteriaForm,
        name: 'criteriaUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin'] },
      },
    ],
  },

  // event-types -----------------------------------------------------------------
  {
    path: '/event-types',
    name: 'eventTypes',
    meta: { userTypes: ['superadmin'] },
    redirect: () => ({ name: 'eventTypes.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|shown|hidden)',
        component: EventTypesPage,
        name: 'eventTypes.tab',
        meta: { userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: EventTypeForm,
        name: 'eventType',
        meta: { mode: 'view', userTypes: ['superadmin'] },
      },
      {
        path: 'create',
        component: EventTypeForm,
        name: 'eventTypeCreate',
        meta: { mode: 'create', userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: EventTypeForm,
        name: 'eventTypeUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin'] },
      },
    ],
  },

  // service-types -----------------------------------------------------------------
  {
    path: '/service-types',
    name: 'serviceTypes',
    meta: { userTypes: ['superadmin'] },
    redirect: () => ({ name: 'serviceTypes.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|shown|hidden)',
        component: ServiceTypesPage,
        name: 'serviceTypes.tab',
        meta: { userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: ServiceTypeForm,
        name: 'serviceType',
        meta: { mode: 'view', userTypes: ['superadmin'] },
      },
      {
        path: 'create',
        component: ServiceTypeForm,
        name: 'serviceTypeCreate',
        meta: { mode: 'create', userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: ServiceTypeForm,
        name: 'serviceTypeUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin'] },
      },
    ],
  },

  // categories -----------------------------------------------------------------
  {
    path: '/categories',
    name: 'categories',
    meta: { userTypes: ['superadmin'] },
    redirect: () => ({ name: 'categories.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|shown|hidden)',
        component: CategoriesPage,
        name: 'categories.tab',
        meta: { userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: CategoryForm,
        name: 'category',
        meta: { mode: 'view', userTypes: ['superadmin'] },
      },
      {
        path: 'create',
        component: CategoryForm,
        name: 'categoryCreate',
        meta: { mode: 'create', userTypes: ['superadmin'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: CategoryForm,
        name: 'categoryUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin'] },
      },
    ],
  },
];

export default routes;
