import { ServiceType } from "./serviceTypeModel";

export class EventType {
    id: string;
    displayName: string;
    status: 'shown' | 'hidden';
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format

    // Relations
    serviceTypes: ServiceType[];

    constructor() {
        this.id = '';
        this.displayName = '';
        this.status = 'shown'; // Default status
        this.createdAt = new Date();
        this.updatedAt = new Date();

        this.serviceTypes = [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromData(data: any): EventType {
        const eventType = new EventType();

        eventType.id = data.id || '';
        eventType.displayName = data.display_name || '';
        eventType.status = data.status || 'shown';
        eventType.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        eventType.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();

        eventType.serviceTypes = data.service_types
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ? data.service_types.map((type: any) => ServiceType.fromData(type))
            : [];

        return eventType;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        return {
            id: this.id,
            display_name: this.displayName,
            status: this.status,
            created_at: this.createdAt.toISOString(),
            updated_at: this.updatedAt.toISOString(),

            // sync attributes
            service_type_ids: (this.serviceTypes ?? []).map((serviceType) => serviceType.id),
        };
    }
}