/* eslint-disable @typescript-eslint/no-explicit-any */
import { Booking } from './bookingModel';
import { Client } from './clientModel';

export class Payment {
  id: string;
  clientId: string;
  amountWithoutDiscount: number;
  discountAmount: number;
  taxAmount: number;
  totalAmount: number;
  paymentGateway: 'paylink' | 'cash' | 'transfer' | null;
  status: 'created' | 'pending' | 'accepted' | 'rejected' | 'expired';
  transactionNumber: string | null; // Nullable field
  paymentUrl: string | null; // Nullable field
  paymentGatewayResponse: string | null;
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Attributes
  paymentNumber: string;

  // Relationships
  client: Client;
  bookings: Booking[];

  constructor() {
    this.id = '';
    this.clientId = '';
    this.amountWithoutDiscount = 0;
    this.discountAmount = 0;
    this.taxAmount = 0;
    this.totalAmount = 0;
    this.paymentGateway = null;
    this.status = 'created';
    this.transactionNumber = null;
    this.paymentUrl = null;
    this.paymentGatewayResponse = null;
    this.paymentNumber = '';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.client = new Client();
    this.bookings = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): Payment {
    const payment = new Payment();

    payment.id = data.id || '';
    payment.clientId = data.client_id || '';
    payment.amountWithoutDiscount =
      data.amount_without_discount !== undefined
        ? parseFloat(data.amount_without_discount)
        : 0;
    payment.discountAmount =
      data.discount_amount !== undefined ? parseFloat(data.discount_amount) : 0;
    payment.taxAmount =
      data.tax_amount !== undefined ? parseFloat(data.tax_amount) : 0;
    payment.totalAmount =
      data.total_amount !== undefined ? parseFloat(data.total_amount) : 0;
    payment.paymentGateway = data.payment_gateway || null;
    payment.status = data.status || 'created';
    payment.transactionNumber = data.transaction_number || null;
    payment.paymentUrl = data.payment_url || null;
    payment.paymentGatewayResponse = data.payment_gateway_response || null;
    payment.paymentNumber = data.payment_number || '';
    payment.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    payment.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    payment.client = data.client ? Client.fromData(data.client) : new Client();
    payment.bookings = data.bookings
      ? data.bookings.map((booking: any) => Booking.fromData(booking))
      : [];

    return payment;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      client_id: this.clientId,
      amount_without_discount: this.amountWithoutDiscount,
      discount_amount: this.discountAmount,
      tax_amount: this.taxAmount,
      total_amount: this.totalAmount,
      payment_gateway: this.paymentGateway,
      status: this.status,
      transaction_number: this.transactionNumber,
      payment_url: this.paymentUrl,
      payment_gateway_response: this.paymentGatewayResponse,
      payment_number: this.paymentNumber,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),
      client: this.client.toData(),
    };
  }
}
