import { Client } from './clientModel';
import { Merchant } from './merchantModel';

export class ClientReview {
  id: string;
  clientId: string;
  merchantId: string;
  bookingId: string;
  serviceId: string;
  servicePackageId: string;
  rate: number; // 1 to 5
  comment: string;
  status: 'pending' | 'shown' | 'hidden';
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relations
  client: Client;
  merchant: Merchant;

  constructor() {
    this.id = '';
    this.clientId = '';
    this.merchantId = '';
    this.bookingId = '';
    this.serviceId = '';
    this.servicePackageId = '';
    this.rate = 5;
    this.comment = '';
    this.status = 'pending';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.client = new Client();
    this.merchant = new Merchant();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): ClientReview {
    const review = new ClientReview();

    review.id = data.id || '';
    review.clientId = data.client_id || '';
    review.merchantId = data.merchant_id || '';
    review.bookingId = data.booking_id || '';
    review.serviceId = data.service_id || '';
    review.servicePackageId = data.service_package_id || '';
    review.rate = data.rate !== undefined ? parseInt(data.rate, 10) : 1;
    review.comment = data.comment || '';
    review.status = data.status || 'pending';
    review.createdAt = data.created_at ? new Date(data.created_at) : new Date();
    review.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();

    review.client = data.client ? Client.fromData(data.client) : new Client();
    review.merchant = data.merchant
      ? Merchant.fromData(data.merchant)
      : new Merchant();

    return review;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      client_id: this.clientId,
      merchant_id: this.merchantId,
      booking_id: this.bookingId,
      service_id: this.serviceId,
      service_package_id: this.servicePackageId,
      rate: this.rate,
      comment: this.comment,
      status: this.status,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),
      client: this.client.toData(),
      merchant: this.merchant.toData(),
    };
  }
}
