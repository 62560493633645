import { Client } from './clientModel';
import { Merchant } from './merchantModel';
import { ServicePackage } from './servicePackageModel';
import { ClientEvent } from './clientEventModel';
import { Payment } from './paymentModel';
import { ClientReview } from './clientReviewModel';

export class Booking {
  id: string;
  clientId: string;
  merchantId: string;
  clientEventId: string;
  servicePackageId: string;
  eventDate: Date; // ISO format
  eventAddress: string;
  paymentId: string;
  paymentMethod: string;
  price: number; // Price in SAR (without discount or tax)
  taxPercentage: number; // Tax percentage (0.00-100.00)
  discountCodeId: string;
  discountPercentage: number; // Discount percentage (0-100)
  status: 'requested' | 'accepted' | 'rejected' | 'paid' | 'canceled';
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Attributes
  bookingNumber: string;
  netPrice: number; // Actual price after tax and discount

  // frontend properties
  priceWithoutDiscount: number;

  // Relations
  client: Client;
  merchant: Merchant;
  servicePackage: ServicePackage;
  clientEvent: ClientEvent;
  payment?: Payment;
  clientReview?: ClientReview;

  constructor() {
    this.id = '';
    this.clientId = '';
    this.merchantId = '';
    this.clientEventId = '';
    this.servicePackageId = '';
    this.eventDate = new Date();
    this.eventAddress = '';
    this.paymentId = '';
    this.paymentMethod = '';
    this.price = 0;
    this.taxPercentage = 0;
    this.discountCodeId = '';
    this.discountPercentage = 0;
    this.status = 'requested';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    // Attributes
    this.bookingNumber = '';
    this.netPrice = 0;

    // frontend properties
    this.priceWithoutDiscount = 0;

    // Relations
    this.client = new Client();
    this.merchant = new Merchant();
    this.servicePackage = new ServicePackage();
    this.clientEvent = new ClientEvent();
    this.payment = undefined;
    this.clientReview = undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): Booking {
    const booking = new Booking();

    booking.id = data.id || '';
    booking.clientId = data.client_id || '';
    booking.merchantId = data.merchant_id || '';
    booking.clientEventId = data.client_event_id || '';
    booking.servicePackageId = data.service_package_id || '';
    booking.eventDate = data.event_date
      ? new Date(data.event_date)
      : new Date();
    booking.eventAddress = data.event_address || '';
    booking.paymentId = data.payment_id || undefined;
    booking.paymentMethod = data.payment_method || '';
    booking.price = data.price !== undefined ? parseFloat(data.price) : 0;
    booking.taxPercentage =
      data.tax_percentage !== undefined ? parseFloat(data.tax_percentage) : 0;
    booking.discountCodeId = data.discount_code_id || undefined;
    booking.discountPercentage =
      data.discount_percentage !== undefined
        ? parseInt(data.discount_percentage, 10)
        : 0;
    booking.status = data.status || 'requested';
    booking.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    booking.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();
    booking.bookingNumber = data.booking_number || '';
    booking.netPrice =
      data.net_price !== undefined ? parseFloat(data.net_price) : 0;

    booking.client = data.client ? Client.fromData(data.client) : new Client();
    booking.merchant = data.merchant
      ? Merchant.fromData(data.merchant)
      : new Merchant();
    booking.servicePackage = data.service_package
      ? ServicePackage.fromData(data.service_package)
      : new ServicePackage();
    booking.clientEvent = data.client_event
      ? ClientEvent.fromData(data.client_event)
      : new ClientEvent();
    booking.payment = data.payment ? Payment.fromData(data.payment) : undefined;
    booking.clientReview = data.client_review
      ? ClientReview.fromData(data.client_review)
      : undefined;

    booking.priceWithoutDiscount =
      (data.price * (100 + booking.taxPercentage)) / 100;
    return booking;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      client_id: this.clientId,
      merchant_id: this.merchantId,
      client_event_id: this.clientEventId,
      service_package_id: this.servicePackageId,
      event_date: this.eventDate.toISOString().split('T')[0],
      event_address: this.eventAddress,
      payment_id: this.paymentId,
      payment_method: this.paymentMethod,
      price: this.price,
      tax_percentage: this.taxPercentage,
      discount_code_id: this.discountCodeId,
      discount_percentage: this.discountPercentage,
      status: this.status,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),

      // Attributes
      booking_number: this.bookingNumber,
      net_price: this.netPrice,

      // frontend properties
      price_without_discount: this.priceWithoutDiscount,
      has_discount: this.hasDiscount(),

      // Relations
      client: this.client.toData(),
      merchant: this.merchant.toData(),
      service_package: this.servicePackage.toData(),
      client_event: this.clientEvent.toData(),
      payment: this.payment?.toData() || null,
      client_review: this.clientReview?.toData() || null,
    };
  }

  hasDiscount(): boolean {
    const roundedPriceWithoutDiscount =
      Math.round(this.priceWithoutDiscount * 100) / 100;
    const roundedNetPrice = Math.round(this.netPrice * 100) / 100;

    return roundedPriceWithoutDiscount > roundedNetPrice;
  }
}
