import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createPinia, type Pinia } from 'pinia';

/** Pinia Store */
const pinia: Pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export default pinia;

import useConfigStore from './config.store';
import useAuthStore from './auth.store';
import useDataStore from './data.store';

export {
    useConfigStore,
    useAuthStore,
    useDataStore
};

/**
 * persist options:
 * - storage:
 *   - localStorage: data persists even after closing the browser
 *   - sessionStorage: Clears when the browser tab is closed
 * - paths: Instead of saving the entire store, you can choose specific properties to persist.
 * - If you want real-time syncing across tabs, use BroadcastChannel API. (pinia-sync)
 */
