import { Client } from "./clientModel";
import { ServicePackage } from "./servicePackageModel";

export class Favorite {
    id: string;
    clientId: string;
    servicePackageId: string;
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format

    // Relations
    client: Client;
    servicePackage: ServicePackage;

    constructor() {
        this.id = '';
        this.clientId = '';
        this.servicePackageId = '';
        this.createdAt = new Date();
        this.updatedAt = new Date();

        this.client = new Client();
        this.servicePackage = new ServicePackage();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromData(data: any): Favorite {
        const favorite = new Favorite();

        favorite.id = data.id || '';
        favorite.clientId = data.client_id || '';
        favorite.servicePackageId = data.service_package_id || '';
        favorite.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        favorite.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();

        favorite.client = data.client ? Client.fromData(data.client) : new Client();
        favorite.servicePackage = data.service_package ? ServicePackage.fromData(data.service_package) : new ServicePackage();

        return favorite;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        return {
            id: this.id,
            client_id: this.clientId,
            service_package_id: this.servicePackageId,
            created_at: this.createdAt.toISOString(),
            updated_at: this.updatedAt.toISOString(),

            client_user_id: this.client.userId,
        };
    }
}
