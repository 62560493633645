import { User } from './userModel'; // Assuming you have a User model

export class SupportMessage {
  id: string;
  userId: string;
  solvedBy?: string; // Nullable solvedBy
  message: string;
  messageType: 'question' | 'issue';
  response: string;
  status: 'pending' | 'solved';
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relations
  sender: User; // The sender is a User model
  resolvedBy?: User; // The resolvedBy is a User model (nullable)

  constructor() {
    this.id = '';
    this.userId = '';
    this.solvedBy = '';
    this.message = '';
    this.messageType = 'question';
    this.response = '';
    this.status = 'pending';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    // Relations
    this.sender = new User();
    this.resolvedBy = undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): SupportMessage {
    const supportMessage = new SupportMessage();

    supportMessage.id = data.id || '';
    supportMessage.userId = data.user_id || '';
    supportMessage.solvedBy = data.solved_by || '';
    supportMessage.message = data.message || '';
    supportMessage.messageType = data.message_type || 'question';
    supportMessage.response = data.response || '';
    supportMessage.status = data.status || 'pending';
    supportMessage.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    supportMessage.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    // Relations
    supportMessage.sender = data.sender
      ? User.fromData(data.sender)
      : new User();
    supportMessage.resolvedBy = data.resolved_by
      ? User.fromData(data.resolved_by)
      : undefined;

    return supportMessage;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      user_id: this.userId,
      solved_by: this.solvedBy,
      message: this.message,
      message_type: this.messageType,
      response: this.response,
      status: this.status,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),
      sender: this.sender ? this.sender.toData() : undefined,
      resolved_by: this.resolvedBy ? this.resolvedBy.toData() : undefined,
    };
  }
}
