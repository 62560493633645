type ENV = {
  mode: string;
  app: {
    baseUrl: string;
    apiBaseUrl: string;
    webStorageNamespace: string;
  };
};

const env: ENV = {
  mode: 'local',
  app: {
    baseUrl: process.env.APP_BASE_URL,
    apiBaseUrl: process.env.API_BASE_URL,
    webStorageNamespace: process.env.WEB_STORAGE_NAMESPACE,
  },
};

export default env;
