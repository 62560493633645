import { Merchant } from "./merchantModel"; // Assuming you have a Merchant model
import { Client } from "./clientModel"; // Assuming you have a Client model
import logoImage from '@/assets/logo.png';

export class User {
    id: string;
    fullName: string;
    nationalId: string;
    nationality: string;
    birthdate?: Date;
    phone: string;
    email: string;
    countryId: string;
    latitude: number;
    longitude: number;
    type: 'client' | 'merchant' | 'superadmin';
    status: 'active' | 'inactive' | 'blocked' | 'deleted';
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format
    emailVerifiedAt?: Date;
    phoneVerifiedAt?: Date;

    // Relations
    merchant?: Merchant; // Merchant model (nullable)
    client?: Client; // Client model (nullable)

    // frontend properties
    thumbnail: string;

    constructor() {
        this.id = '';
        this.fullName = '';
        this.nationalId = '';
        this.nationality = '';
        this.birthdate;
        this.phone = '';
        this.email = '';
        this.countryId = '';
        this.latitude = 24.774265;
        this.longitude = 46.738586;
        this.type = 'client';
        this.status = 'inactive';
        this.createdAt = new Date();
        this.updatedAt = new Date();
        this.emailVerifiedAt = undefined;
        this.phoneVerifiedAt = undefined;

        // Relations
        this.merchant = undefined;
        this.client = undefined;

        // frontend properties
        this.thumbnail = logoImage;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromData(data: any): User {
        const user = new User();

        user.id = data.id || '';
        user.fullName = data.full_name || '';
        user.nationalId = data.national_id || '';
        user.nationality = data.nationality || '';
        user.birthdate = data.birthdate ? new Date(data.birthdate) : undefined;
        user.phone = data.phone || '';
        user.email = data.email || '';
        user.countryId = data.country_id || '';
        user.latitude = data.latitude || 24.774265;
        user.longitude = data.longitude || 46.738586;
        user.type = data.type || 'client';
        user.status = data.status || 'inactive';
        user.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        user.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();
        user.emailVerifiedAt = data.email_verified_at ? new Date(data.email_verified_at) : undefined;
        user.phoneVerifiedAt = data.phone_verified_at ? new Date(data.phone_verified_at) : undefined;

        // Relations
        user.merchant = data.merchant ? Merchant.fromData(data.merchant) : undefined;
        user.client = data.client ? Client.fromData(data.client) : undefined;

        // frontend properties
        user.thumbnail = (user.type === 'merchant' && user.merchant) ? user.merchant.logo : logoImage;

        return user;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        const data = {
            id: this.id,
            full_name: this.fullName,
            national_id: this.nationalId,
            nationality: this.nationality,
            birthdate: this.birthdate ? this.birthdate.toISOString().split('T')[0] : null,
            phone: this.phone,
            email: this.email,
            country_id: this.countryId,
            latitude: this.latitude,
            longitude: this.longitude,
            type: this.type,
            status: this.status,
        };

        if (this.merchant) {
            Object.assign(data, {
                business_name: this.merchant.businessName,
                license_type: this.merchant.licenseType,
                license_number: this.merchant.licenseNumber,
                description: this.merchant.description,
                address: this.merchant.address,
                response_time: this.merchant.responseTime,
                is_featured: this.merchant.isFeatured,
                reviews_count: this.merchant.reviewsCount,
                reviews_avg: this.merchant.reviewsAvg,
                logo: this.merchant.logo,
            });
        }

        return data;
    }
}