import { defineStore } from 'pinia';
import { parse, stringify } from 'zipson';

import env from '@/env';
import { type SupportedLanguage, FALLBACK_LANGUAGE } from '@/i18n';
import useAuthStore from './auth.store';

type ConfigState = {
  _themeDark: boolean;
  _locale: string;
  _langDirection: 'ltr' | 'rtl';
  _mode: 'view' | 'create' | 'edit' | undefined;
};

export default defineStore('config', {
  state: (): ConfigState => ({
    _themeDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
    _locale: localStorage.getItem('locale') || FALLBACK_LANGUAGE,
    _langDirection: 'rtl',
    _mode: undefined,
  }),
  getters: {
    themeDark(state): boolean {
      return state._themeDark;
    },
    locale(state): string {
      return state._locale;
    },
    langDirection(state): string {
      return state._langDirection;
    },
    mode(state): string | undefined {
      return state._mode;
    },
  },
  actions: {
    toggleLightDarkMode() {
      this._themeDark = !this._themeDark;
    },
    setLocale(locale: SupportedLanguage) {
      this._locale = locale;
      localStorage.setItem('locale', locale);
      location.reload();
    },
    setLangDirection(langDirection: 'ltr' | 'rtl') {
      this._langDirection = langDirection;
    },
    setMode(mode: 'view' | 'create' | 'edit' | undefined) {
      this._mode = mode;
    },
    stringLang(arabicString: string | null, englishString: string | null) {
      return this._locale === 'en' || arabicString == null
        ? (englishString as string)
        : arabicString;
    },
    superadminEditMode() {
      const authStore = useAuthStore();
      return (
        (this._mode == 'edit' || this._mode == 'create') && authStore.superadmin
      );
    },
    merchantEditMode(superadmin?: boolean) {
      const authStore = useAuthStore();
      const editOrCreate = this._mode === 'edit' || this._mode === 'create';
      const userType = authStore.userType;
      if (superadmin) {
        return editOrCreate && ['superadmin', 'merchant'].includes(userType);
      } else {
        return editOrCreate && userType == 'merchant';
      }
    },
    clientEditMode(superadmin?: boolean) {
      const authStore = useAuthStore();
      const editOrCreate = this._mode === 'edit' || this._mode === 'create';
      const userType = authStore.userType;
      if (superadmin) {
        return editOrCreate && ['superadmin', 'client'].includes(userType);
      } else {
        return editOrCreate && userType == 'client';
      }
    },
  },
  persist: {
    debug: env.mode === 'local',
    key: env.app.webStorageNamespace + '.config.store',
    storage: window.localStorage,
    // paths: ['_themeDark', '_locale', '_langDirection', '_mode'],
    serializer: {
      deserialize: parse,
      serialize: stringify,
    },
  },
});