import { AdvertisementType } from './advertisementTypeModel';
import { ServicePackage } from './servicePackageModel';

export class Advertisement {
  id: string;
  merchantId: string;
  servicePackageId: string;
  title: string;
  subtitle: string;
  description: string;
  thumbnail: string;
  advertisementTypeId: string;
  startDate: Date; // ISO format
  endDate: Date; // ISO format
  fees: number; // Fees in SAR
  taxPercentage: number; // Tax percentage (0.00-100.00)
  status: 'requested' | 'accepted' | 'hidden' | 'rejected' | 'canceled';
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Attributes
  netFees: number; // Fees + Tax in SAR
  isActive: boolean;

  // Relations
  servicePackage: ServicePackage;
  advertisementType: AdvertisementType;

  constructor() {
    this.id = '';
    this.merchantId = '';
    this.servicePackageId = '';
    this.title = '';
    this.subtitle = '';
    this.description = '';
    this.thumbnail = '';
    this.advertisementTypeId = '';
    this.startDate = new Date();
    this.endDate = new Date();
    this.fees = 0;
    this.taxPercentage = 0;
    this.status = 'requested';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.netFees = 0;
    this.isActive = false;

    this.servicePackage = new ServicePackage();
    this.advertisementType = new AdvertisementType();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): Advertisement {
    const advertisement = new Advertisement();

    advertisement.id = data.id || '';
    advertisement.merchantId = data.merchant_id || '';
    advertisement.servicePackageId = data.service_package_id || '';
    advertisement.title = data.title || '';
    advertisement.subtitle = data.subtitle || '';
    advertisement.description = data.description || '';
    advertisement.thumbnail = data.thumbnail || ''; // TODO
    advertisement.advertisementTypeId = data.advertisement_type_id || '';
    advertisement.startDate = data.start_date
      ? new Date(data.start_date)
      : new Date();
    advertisement.endDate = data.end_date
      ? new Date(data.end_date)
      : new Date();
    advertisement.fees = data.fees !== undefined ? parseFloat(data.fees) : 0;
    advertisement.taxPercentage =
      data.tax_percentage !== undefined ? parseFloat(data.tax_percentage) : 0;
    advertisement.status = data.status || 'requested';
    advertisement.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    advertisement.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    advertisement.netFees =
      data.net_fees !== undefined ? parseFloat(data.net_fees) : 0;
    advertisement.isActive =
      data.is_active !== undefined ? Boolean(data.is_active) : false;

    advertisement.servicePackage = data.service_package
      ? ServicePackage.fromData(data.service_package)
      : new ServicePackage();
    advertisement.advertisementType = data.advertisement_type
      ? AdvertisementType.fromData(data.advertisement_type)
      : new AdvertisementType();
    return advertisement;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      merchant_id: this.merchantId,
      service_package_id: this.servicePackageId,
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      thumbnail: this.thumbnail, // TODO
      advertisement_type_id: this.advertisementTypeId,
      start_date: this.startDate.toISOString().split('T')[0], // ISO format
      end_date: this.endDate.toISOString().split('T')[0], // ISO format
      fees: this.fees, // Fees in SAR
      tax_percentage: this.taxPercentage, // Tax percentage (0.00-100.00)
      status: this.status,
      created_at: this.createdAt.toISOString(), // ISO format
      updated_at: this.updatedAt.toISOString(), // ISO format
    };
  }
}
