import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.APP_CRYPTO_KEY; // ⚠️ Must be 16 or 32 chars for AES-128/256

export function encryptData(data: string): string {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
}

export function decryptData(encryptedData: string): string | null {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8) || null;
    } catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
}
