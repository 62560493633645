import { defineStore } from 'pinia';
import { parse, stringify } from 'zipson';

import env from '@/env';
import { User } from '@/api/models/models';
import logoImage from '@/assets/logo.png';
import { encryptData, decryptData } from '@/utils/crypto';
import { backendApi } from '@/http';

type AuthState = {
  _token?: string;
  _fullName: string;
  _phone: string;
  _email: string;
  _thumbnail: string;
  _userType: 'superadmin' | 'client' | 'merchant' | 'guest';
};

export default defineStore('auth', {
  state: (): AuthState => ({
    _token: undefined,
    _fullName: '---',
    _phone: '---',
    _email: '---',
    _thumbnail: logoImage,
    _userType: 'guest',
  }),

  getters: {
    fullName: (state) => state._fullName,
    phone: (state) => state._phone,
    email: (state) => state._email,
    thumbnail: (state) => state._thumbnail,
    userType: (state) => state._userType,

    superadmin: (state) => state._userType === 'superadmin',
    client: (state) => state._userType === 'client',
    merchant: (state) => state._userType === 'merchant',
  },
  actions: {
    setUser(user: User | null) {
      this._fullName = user?.fullName ?? '---';
      this._phone = user?.phone ?? '---';
      this._email = user?.email ?? '---';
      this._thumbnail = user?.thumbnail ?? logoImage;
      this._userType = user?.type ?? 'guest';
    },
    saveToken(token: string) {
      const expirationTime = 5 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
      const currentTime = new Date().getTime();
      const tokenData = {
        token: token,
        expiration: currentTime + expirationTime,
      };
      this._token = JSON.stringify(tokenData);
    },
    getToken() {
      try {
        if (!this._token) {
          throw new Error(); // 'Token not found in local storage'
        }

        const tokenData = JSON.parse(this._token);
        if (!tokenData || !tokenData.expiration) {
          throw new Error(); // 'Invalid token format'
        }

        const currentTime = new Date().getTime();
        if (currentTime > tokenData.expiration) {
          throw new Error(); // 'Token has expired'
        }

        return tokenData.token;
      } catch (error) {
        this.removeUserInfo();
        return null;
      }
    },
    removeUserInfo() {
      this._token = undefined;
      this._fullName = '---';
      this._phone = '---';
      this._email = '---';
      this._thumbnail = logoImage;
      this._userType = 'guest';
    },
    clearStoredData() {
      localStorage.removeItem(env.app.webStorageNamespace + '.a.store');
      // localStorage.removeItem(env.app.webStorageNamespace + '.config.store');
      localStorage.removeItem(env.app.webStorageNamespace + '.data.store');
    },
    async logout() {
      await backendApi.post('auth/logout');
      this.removeUserInfo();
      this.clearStoredData();
    },
  },
  persist: {
    debug: env.mode === 'local',
    key: env.app.webStorageNamespace + '.a.store',
    storage: window.localStorage,
    serializer: {
      serialize(state) {
        const encryptedData = encryptData(stringify(state));
        return encryptedData;
      },
      deserialize(data) {
        const decryptedData = decryptData(data);
        return decryptedData ? parse(decryptedData) : null;
      },
    },
  },
});
