import { type RouteRecordRaw } from 'vue-router';

import auth_routes from '@/router/routes/auth_routes';
import setting_routes from '@/router/routes/setting_routes';
import system_routes from '@/router/routes/system_routes';
import merchant_routes from '@/router/routes/merchant_routes';
import client_routes from '@/router/routes/client_routes';

export const routes: RouteRecordRaw[] = [
  ...auth_routes,
  ...setting_routes,
  ...system_routes,
  ...merchant_routes,
  ...client_routes,
];
