export class ServiceImage {
  id: string;
  servicePackageId: string;
  imagePath: string;

  constructor() {
    this.id = '';
    this.servicePackageId = '';
    this.imagePath = '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): ServiceImage {
    const serviceImage = new ServiceImage();

    serviceImage.id = data.id || '';
    serviceImage.servicePackageId = data.service_package_id || '';
    serviceImage.imagePath = data.image_path || '';

    return serviceImage;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      service_package_id: this.servicePackageId,
      image_path: this.imagePath,
    };
  }
}
