import { type RouteRecordRaw } from 'vue-router';

// Favorites
import FavoriteForm from '@/views/favorites/FavoriteForm.vue';
import FavoritesPage from '@/views/favorites/FavoritesPage.vue';

// Bookings
import BookingForm from '@/views/bookings/BookingForm.vue';
import BookingsPage from '@/views/bookings/BookingsPage.vue';

// Payments
import PaymentForm from '@/views/payments/PaymentForm.vue';
import PaymentsPage from '@/views/payments/PaymentsPage.vue';

// Client Events
import ClientEventForm from '@/views/clientEvents/ClientEventForm.vue';
import ClientEventsPage from '@/views/clientEvents/ClientEventsPage.vue';

const uuidRegex =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

const routes: RouteRecordRaw[] = [
  // favorites -----------------------------------------------------------------
  {
    path: '/favorites',
    name: 'favorites',
    meta: { userTypes: ['superadmin', 'client'] },
    redirect: () => ({ name: 'favorites.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all)',
        component: FavoritesPage,
        name: 'favorites.tab',
        meta: { userTypes: ['superadmin', 'client'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: FavoriteForm,
        name: 'favorite',
        meta: { mode: 'view', userTypes: ['superadmin', 'client'] },
      },
      {
        path: 'create',
        component: FavoriteForm,
        name: 'favoriteCreate',
        meta: { mode: 'create', userTypes: ['client'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: FavoriteForm,
        name: 'favoriteUpdate',
        meta: { mode: 'edit', userTypes: ['client'] },
      },
    ],
  },

  // bookings -----------------------------------------------------------------
  {
    path: '/bookings',
    name: 'bookings',
    meta: { userTypes: ['superadmin', 'merchant', 'client'] },
    redirect: () => ({ name: 'bookings.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|requested|accepted|rejected|paid|canceled)',
        component: BookingsPage,
        name: 'bookings.tab',
        meta: { userTypes: ['superadmin', 'merchant', 'client'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: BookingForm,
        name: 'booking',
        meta: { mode: 'view', userTypes: ['superadmin', 'merchant', 'client'] },
      },
      {
        path: 'create',
        component: BookingForm,
        name: 'bookingCreate',
        meta: { mode: 'create', userTypes: ['client'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: BookingForm,
        name: 'bookingUpdate',
        meta: { mode: 'edit', userTypes: ['merchant', 'client'] },
      },
    ],
  },

  // payments -----------------------------------------------------------------
  {
    path: '/payments',
    name: 'payments',
    meta: { userTypes: ['superadmin', 'client', 'merchant'] },
    redirect: () => ({ name: 'payments.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|pending|accepted|rejected|expired)',
        component: PaymentsPage,
        name: 'payments.tab',
        meta: { userTypes: ['superadmin', 'client', 'merchant'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: PaymentForm,
        name: 'payment',
        meta: { mode: 'view', userTypes: ['superadmin', 'client', 'merchant'] },
      },
      {
        path: 'create',
        component: PaymentForm,
        name: 'paymentCreate',
        meta: { mode: 'create', userTypes: ['merchant', 'client'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: PaymentForm,
        name: 'paymentUpdate',
        meta: { mode: 'edit', userTypes: [] },
      },
    ],
  },

  // client-events -----------------------------------------------------------------
  {
    path: '/client-events',
    name: 'clientEvents',
    meta: { userTypes: ['superadmin', 'client'] },
    redirect: () => ({ name: 'clientEvents.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|active|archived)',
        component: ClientEventsPage,
        name: 'clientEvents.tab',
        meta: { userTypes: ['superadmin', 'client'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: ClientEventForm,
        name: 'clientEvent',
        meta: { mode: 'view', userTypes: ['superadmin', 'client'] },
      },
      {
        path: 'create',
        component: ClientEventForm,
        name: 'clientEventCreate',
        meta: { mode: 'create', userTypes: ['client'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: ClientEventForm,
        name: 'clientEventUpdate',
        meta: { mode: 'edit', userTypes: ['client'] },
      },
    ],
  },
];

export default routes;
