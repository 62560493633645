export class AdvertisementType {
    id: string;
    title: string;
    typeIdentifier: string;
    description: string;
    pricePerDay: number; // Price per day in SAR
    taxPercentage: number; // Tax percentage (0.00-100.00)
    status: 'shown' | 'hidden';
    createdAt: Date; // ISO format
    updatedAt: Date; // ISO format

    constructor() {
        this.id = '';
        this.title = '';
        this.typeIdentifier = '';
        this.description = '';
        this.pricePerDay = 0;
        this.taxPercentage = 0;
        this.status = 'shown';
        this.createdAt = new Date();
        this.updatedAt = new Date();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromData(data: any): AdvertisementType {
        const advertisementType = new AdvertisementType();

        advertisementType.id = data.id || '';
        advertisementType.title = data.title || '';
        advertisementType.typeIdentifier = data.type_identifier || '';
        advertisementType.description = data.description || '';
        advertisementType.pricePerDay = data.price_per_day !== undefined ? parseFloat(data.price_per_day) : 0;
        advertisementType.taxPercentage = data.tax_percentage !== undefined ? parseFloat(data.tax_percentage) : 0;
        advertisementType.status = data.status || 'shown';
        advertisementType.createdAt = data.created_at ? new Date(data.created_at) : new Date();
        advertisementType.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();

        return advertisementType;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toData(): Record<string, any> {
        return {
            id: this.id,
            title: this.title,
            type_identifier: this.typeIdentifier,
            description: this.description,
            price_per_day: this.pricePerDay,
            tax_percentage: this.taxPercentage,
            status: this.status,
            created_at: this.createdAt.toISOString(),
            updated_at: this.updatedAt.toISOString(),
        };
    }
}
