import axios, { AxiosError } from 'axios';
import { useAuthStore } from '@/stores';

const apiBaseUrl = process.env.API_BASE_URL;
const apiVersion = process.env.API_VERSION;

const csrfApi = axios.create({
  baseURL: apiBaseUrl + '/sanctum/csrf-cookie',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

const backendApi = axios.create({
  baseURL: `${apiBaseUrl}/api/${apiVersion}`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

// Add an interceptor to handle the token
backendApi.interceptors.request.use(
  (config) => {
    // stores
    const authStore = useAuthStore();

    const token = authStore.getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { backendApi, csrfApi, axios, AxiosError };
