import { User } from './userModel';

export class Client {
  id: string;
  userId: string;
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relations
  user: User;

  constructor() {
    this.id = '';
    this.userId = '';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.user = new User();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): Client {
    const client = new Client();

    client.id = data.id || '';
    client.userId = data.user_id || '';
    client.createdAt = data.created_at ? new Date(data.created_at) : new Date();
    client.updatedAt = data.updated_at ? new Date(data.updated_at) : new Date();

    client.user = data.user ? User.fromData(data.user) : new User();

    return client;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      user_id: this.userId,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),
      user: this.user.toData(),
    };
  }
}
