import { ServicePackage } from './servicePackageModel';

export class DiscountCode {
  id: string;
  merchantId: string;
  code: string;
  percentage: number; // 1-100
  startDate: Date; // ISO format
  endDate: Date | null; // ISO format or null for unlimited
  quantity: number | null; // null means unlimited
  bookingCounter: number; // Tracks usage
  autoApply: boolean; // If true, auto-applied
  status: 'active' | 'inactive'; // Status of the discount
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relations
  servicePackages: ServicePackage[];

  constructor() {
    this.id = '';
    this.merchantId = '';
    this.code = '';
    this.percentage = 0;
    this.startDate = new Date();
    this.endDate = null;
    this.quantity = null;
    this.bookingCounter = 0;
    this.autoApply = false;
    this.status = 'active';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.servicePackages = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): DiscountCode {
    const discountCode = new DiscountCode();

    discountCode.id = data.id || '';
    discountCode.merchantId = data.merchant_id || '';
    discountCode.code = data.code || '';
    discountCode.percentage =
      data.percentage !== undefined ? parseInt(data.percentage, 10) : 0;
    discountCode.startDate = data.start_date
      ? new Date(data.start_date)
      : new Date();
    discountCode.endDate = data.end_date ? new Date(data.end_date) : null;
    discountCode.quantity =
      data.quantity !== undefined ? parseInt(data.quantity, 10) : null;
    discountCode.bookingCounter =
      data.booking_counter !== undefined
        ? parseInt(data.booking_counter, 10)
        : 0;
    discountCode.autoApply =
      data.auto_apply !== undefined ? Boolean(data.auto_apply) : false;
    discountCode.status = data.status || 'active';
    discountCode.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    discountCode.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    discountCode.servicePackages = data.service_packages
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.service_packages.map((pkg: any) => ServicePackage.fromData(pkg))
      : [];

    return discountCode;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      merchant_id: this.merchantId,
      code: this.code,
      percentage: this.percentage,
      start_date: this.startDate.toISOString().split('T')[0],
      end_date: this.endDate ? this.endDate.toISOString().split('T')[0] : null,
      quantity: this.quantity,
      booking_counter: this.bookingCounter,
      auto_apply: this.autoApply,
      status: this.status,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),

      // sync attributes
      service_package_ids: (this.servicePackages ?? []).map((pkg) => pkg.id),
    };
  }
}
