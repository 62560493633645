import { type RouteRecordRaw } from 'vue-router';

// Advertisements
import AdvertisementForm from '@/views/advertisements/AdvertisementForm.vue';
import AdvertisementsPage from '@/views/advertisements/AdvertisementsPage.vue';

// Discount Codes
import DiscountCodeForm from '@/views/discountCodes/DiscountCodeForm.vue';
import DiscountCodesPage from '@/views/discountCodes/DiscountCodesPage.vue';

// Services
import ServiceForm from '@/views/services/ServiceForm.vue';
import ServicesPage from '@/views/services/ServicesPage.vue';

// Service Packages
import ServicePackageForm from '@/views/servicePackages/ServicePackageForm.vue';
import ServicePackagesPage from '@/views/servicePackages/ServicePackagesPage.vue';

// Media Files
import MediaFileForm from '@/views/mediaFiles/MediaFileForm.vue';
import MediaFilesPage from '@/views/mediaFiles/MediaFilesPage.vue';

const uuidRegex =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

const routes: RouteRecordRaw[] = [
  // advertisements -----------------------------------------------------------------
  {
    path: '/advertisements',
    name: 'advertisements',
    meta: { userTypes: ['superadmin', 'merchant'] },
    redirect: () => ({ name: 'advertisements.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|requested|accepted|rejected|canceled)',
        component: AdvertisementsPage,
        name: 'advertisements.tab',
        meta: { userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: AdvertisementForm,
        name: 'advertisement',
        meta: { mode: 'view', userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: 'create',
        component: AdvertisementForm,
        name: 'advertisementCreate',
        meta: { mode: 'create', userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: AdvertisementForm,
        name: 'advertisementUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin', 'merchant'] },
      },
    ],
  },

  // discount-codes -----------------------------------------------------------------
  {
    path: '/discount-codes',
    name: 'discountCodes',
    meta: { userTypes: ['superadmin', 'merchant'] },
    redirect: () => ({ name: 'discountCodes.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|active|inactive)',
        component: DiscountCodesPage,
        name: 'discountCodes.tab',
        meta: { userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: DiscountCodeForm,
        name: 'discountCode',
        meta: { mode: 'view', userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: 'create',
        component: DiscountCodeForm,
        name: 'discountCodeCreate',
        meta: { mode: 'create', userTypes: ['merchant'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: DiscountCodeForm,
        name: 'discountCodeUpdate',
        meta: { mode: 'edit', userTypes: ['merchant'] },
      },
    ],
  },

  // service-packages -----------------------------------------------------------------
  {
    path: '/service-packages',
    name: 'servicePackages',
    meta: { userTypes: ['superadmin', 'merchant'] },
    redirect: () => ({ name: 'servicePackages.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|shown|hidden)',
        component: ServicePackagesPage,
        name: 'servicePackages.tab',
        meta: { userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: ServicePackageForm,
        name: 'servicePackage',
        meta: { mode: 'view', userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: 'create',
        component: ServicePackageForm,
        name: 'servicePackageCreate',
        meta: { mode: 'create', userTypes: ['merchant'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: ServicePackageForm,
        name: 'servicePackageUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin', 'merchant'] },
      },
    ],
  },

  // services -----------------------------------------------------------------
  {
    path: '/services',
    name: 'services',
    meta: { userTypes: ['superadmin', 'merchant'] },
    redirect: () => ({ name: 'services.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|accepted|rejected|pending|hidden)',
        component: ServicesPage,
        name: 'services.tab',
        meta: { userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: `:id(${uuidRegex})`,
        component: ServiceForm,
        name: 'service',
        meta: { mode: 'view', userTypes: ['superadmin', 'merchant'] },
      },
      {
        path: 'create',
        component: ServiceForm,
        name: 'serviceCreate',
        meta: { mode: 'create', userTypes: ['merchant'] },
      },
      {
        path: `:id(${uuidRegex})/edit`,
        component: ServiceForm,
        name: 'serviceUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin', 'merchant'] },
      },
    ],
  },

  // media files -----------------------------------------------------------------
  {
    path: '/media-files',
    name: 'mediaFiles',
    component: MediaFilesPage,
    meta: { userTypes: ['superadmin', 'merchant'] },
  },
  {
    path: `/media-files/:id(${uuidRegex})`,
    component: MediaFileForm,
    name: 'mediaFile',
    meta: { mode: 'view', userTypes: ['superadmin', 'merchant'] },
  },
  {
    path: `/media-files/:id(${uuidRegex})/edit`,
    component: MediaFileForm,
    name: 'mediaFileUpdate',
    meta: { mode: 'edit', userTypes: ['superadmin', 'merchant'] },
  },

];

export default routes;
