/* eslint-disable @typescript-eslint/no-explicit-any */
import { Merchant } from './merchantModel'; // Assuming you have a model for Merchant
import { ServiceType } from './serviceTypeModel'; // Assuming you have a model for ServiceType
import { ServicePackage } from './servicePackageModel'; // Assuming you have a model for ServicePackage
import { City } from './cityModel'; // Assuming you have a model for City

export class Service {
  id: string;
  merchantId: string;
  serviceTypeId: string;
  displayName: string;
  description: string;
  bookBeforeDays: number;
  thumbnail: string;
  latitude: number;
  longitude: number;
  isFeatured: boolean;
  status: 'pending' | 'accepted' | 'rejected' | 'hidden';
  reviewsCount: number;
  reviewsAvg: number;
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relationships
  merchant: Merchant; // Assuming Merchant is a related model
  serviceType: ServiceType; // Assuming ServiceType is a related model
  servicePackages: ServicePackage[]; // Assuming ServicePackage is a related model
  cities: City[]; // Assuming City is a related model

  constructor() {
    this.id = '';
    this.merchantId = '';
    this.serviceTypeId = '';
    this.displayName = '';
    this.description = '';
    this.bookBeforeDays = 1;
    this.thumbnail = '';
    this.latitude = 0;
    this.longitude = 0;
    this.isFeatured = false;
    this.status = 'pending';
    this.reviewsCount = 0;
    this.reviewsAvg = 0;
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.merchant = new Merchant();
    this.serviceType = new ServiceType();
    this.servicePackages = [];
    this.cities = [];
  }

  static fromData(data: any): Service {
    const service = new Service();

    service.id = data.id || '';
    service.merchantId = data.merchant_id || '';
    service.serviceTypeId = data.service_type_id || '';
    service.displayName = data.display_name || '';
    service.description = data.description || '';
    service.bookBeforeDays = data.book_before_days || 1;
    service.thumbnail = data.thumbnail || '';
    service.latitude = data.latitude || 0;
    service.longitude = data.longitude || 0;
    service.isFeatured = data.is_featured || false;
    service.status = data.status || 'pending';
    service.reviewsCount = data.reviews_count || 0;
    service.reviewsAvg = data.reviews_avg || 0;
    service.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    service.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    service.merchant = data.merchant
      ? Merchant.fromData(data.merchant)
      : new Merchant();
    service.serviceType = data.service_type
      ? ServiceType.fromData(data.service_type)
      : new ServiceType();
    service.servicePackages = data.service_packages
      ? data.service_packages.map((packageData: any) =>
        ServicePackage.fromData(packageData)
      )
      : [];
    service.cities = data.cities
      ? data.cities.map((cityData: any) => City.fromData(cityData))
      : [];

    return service;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      merchant_id: this.merchantId,
      service_type_id: this.serviceTypeId,
      display_name: this.displayName,
      description: this.description,
      book_before_days: this.bookBeforeDays,
      thumbnail: this.thumbnail,
      latitude: this.latitude,
      longitude: this.longitude,
      is_featured: this.isFeatured,
      status: this.status,
      reviews_count: this.reviewsCount,
      reviews_avg: this.reviewsAvg,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),

      // Relationships
      merchant: this.merchant.toData(),
      service_type: this.serviceType.toData(),
      service_packages: this.servicePackages.map((packageData) =>
        packageData.toData()
      ),
      cities: this.cities.map((cityData) => cityData.toData()),

      // sync attributes
      city_ids: (this.cities ?? []).map((city) => city.id),
    };
  }
}
