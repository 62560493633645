/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventType } from './eventTypeModel'; // Assuming you have a model for EventType
import { Criteria } from './criteriaModel'; // Assuming you have a model for Criteria
import { Service } from './serviceModel'; // Assuming you have a model for Service
import { Category } from './categoryModel'; // Assuming you have a model for Category

export class ServiceType {
  id: string;
  categoryId: string;
  displayName: string;
  status: 'shown' | 'hidden';
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Relationships
  eventTypes: EventType[]; // Assuming EventType is a related model
  criterias: Criteria[]; // Assuming Criteria is a related model
  services: Service[]; // Assuming Service is a related model
  category: Category; // Assuming Category is a related model

  constructor() {
    this.id = '';
    this.categoryId = '';
    this.displayName = '';
    this.status = 'shown';
    this.createdAt = new Date();
    this.updatedAt = new Date();

    this.eventTypes = [];
    this.criterias = [];
    this.services = [];
    this.category = new Category();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): ServiceType {
    const serviceType = new ServiceType();

    serviceType.id = data.id || '';
    serviceType.categoryId = data.category_id || '';
    serviceType.displayName = data.display_name || '';
    serviceType.status = data.status || 'shown';
    serviceType.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    serviceType.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    serviceType.eventTypes = data.event_types
      ? data.event_types.map((eventType: any) => EventType.fromData(eventType))
      : [];
    serviceType.criterias = data.criterias
      ? data.criterias.map((criteria: any) => Criteria.fromData(criteria))
      : [];
    serviceType.services = data.services
      ? data.services.map((service: any) => Service.fromData(service))
      : [];
    serviceType.category = data.category
      ? Category.fromData(data.category)
      : new Category();

    return serviceType;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      category_id: this.categoryId,
      display_name: this.displayName,
      status: this.status,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),

      // sync attributes
      event_type_ids: (this.eventTypes ?? []).map((eventType) => eventType.id),
      criteria_ids: (this.criterias ?? []).map((criteria) => criteria.id),
    };
  }
}
