import { useConfigStore } from '@/stores';

// (1546.1235) -> SAR 1,546.12
function currency(amount: number, currency?: string): string {
  const configStore = useConfigStore();
  const locale = configStore.locale;
  currency = currency || 'SAR';
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    amount
  );
}

// (15) -> 15 %
function formatPercentage(amount: number): string {
  return amount.toFixed(2) + ' %';
}

// (1546.1235) -> 1546.12
function formatNumber(
  number?: number | null,
  nDigits?: number
): string | null {
  if (!number) {
    return null;
  }

  return number.toFixed(nDigits);
}

// Dynamic Date Formatter
function formatDate(date: Date, format?: string): string {
  const map: Record<string, string> = {
    yyyy: date.getFullYear().toString(),
    yy: date.getFullYear().toString().slice(-2),
    mm: String(date.getMonth() + 1).padStart(2, '0'),
    dd: String(date.getDate()).padStart(2, '0'),
    hh: String(date.getHours()).padStart(2, '0'),
    mm2: String(date.getMinutes()).padStart(2, '0'), // Avoid conflict with 'mm'
    ss: String(date.getSeconds()).padStart(2, '0'),
  };

  // Default format
  format = format || 'yyyy-mm-dd';

  return format
    .replace(/yyyy/g, map.yyyy)
    .replace(/yy/g, map.yy)
    .replace(/mm/g, map.mm)
    .replace(/dd/g, map.dd)
    .replace(/hh/g, map.hh)
    .replace(/mm/g, map.mm2) // Replace 'mm' after year/month replacement
    .replace(/ss/g, map.ss);
}

function formatDateTime(date: Date, format?: string): string {
  format = format || 'yyyy-mm-dd hh:mm:ss';
  return formatDate(date, format);
}


export default {
  currency: currency,
  percentage: formatPercentage,
  number: formatNumber,
  date: formatDate,
  dateTime: formatDateTime,
};
