export class MediaFile {
  id: string;
  merchantId: string | null; // Nullable field
  directory: string;
  caption: string;
  altText: string;
  imageUrl: string;
  createdAt: Date; // ISO format
  updatedAt: Date; // ISO format

  // Constructor
  constructor() {
    this.id = '';
    this.merchantId = null;
    this.directory = '';
    this.caption = '';
    this.altText = '';
    this.imageUrl = '';
    this.createdAt = new Date();
    this.updatedAt = new Date();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromData(data: any): MediaFile {
    const mediaFile = new MediaFile();

    mediaFile.id = data.id || '';
    mediaFile.merchantId = data.merchant_id || null;
    mediaFile.directory = data.directory || '';
    mediaFile.caption = data.caption || '';
    mediaFile.altText = data.alt_text || '';
    mediaFile.imageUrl = data.image_url || '';
    mediaFile.createdAt = data.created_at
      ? new Date(data.created_at)
      : new Date();
    mediaFile.updatedAt = data.updated_at
      ? new Date(data.updated_at)
      : new Date();

    return mediaFile;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toData(): Record<string, any> {
    return {
      id: this.id,
      merchant_id: this.merchantId,
      directory: this.directory,
      caption: this.caption,
      alt_text: this.altText,
      image_url: this.imageUrl,
      created_at: this.createdAt.toISOString(),
      updated_at: this.updatedAt.toISOString(),
    };
  }
}
